import Fuse from 'fuse.js'


export interface CartItem {
    product?: Product,
    variant: Variant,
    quantity: number,
};

export class Cart {

    items: CartItem[] = [];
}

export class Model {

    cartClass = '';
    search: string = '';
    filter: any = {};

    products: Product[] = [];


    cart = new Cart();
    cartOpen: any;



    constructor() {
        if (localStorage.getItem('cart')) {
            this.cart = JSON.parse(localStorage.getItem('cart') as any);
        }
    }




}


export type Action
    = { type: 'add', variant: Variant, quantity: number, product: Product }
    | { type: 'remove', id: number }
    | { type: 'quantity', id: number, quantity: number }
    | { type: 'products', products: Product[] }
    | { type: 'filter', filter: any }
    | { type: 'filter.in', key: string, option: string, value: boolean }
    | { type: 'search', value: string }
    | { type: 'filter.clear' }
    | { type: 'clear_cart' }
    | { type: 'openCart', value: boolean }
    | { type: 'endanimate' };


let fuse = new Fuse([]);

export function RootReducer(state: Model, action: Action) {
    console.log(action);
    switch (action.type) {
        case 'openCart':
            state.cartOpen = action.value;
            break;
        case 'clear_cart':
            state.cart.items = [];
            localStorage.setItem('cart', JSON.stringify(state.cart));
            break;
        case 'filter.clear':
            state.search = '';
            state.filter = {};
            break;
        case 'search':
            state.search = action.value;
            break;
        case 'add':
            const item = state.cart.items.find(it => it.variant.id == action.variant.id);
            if (item) {
                item.quantity += action.quantity;
            } else {
                state.cart.items.push({ variant: action.variant, quantity: action.quantity, product: action.product });
            }
            state.cartClass = 'animate__animated animate__headShake';

            localStorage.setItem('cart', JSON.stringify(state.cart));
            break;
        case 'quantity':
            // update quantity
            state.cart.items.filter(it => it.variant.id == action.id).forEach(it => it.quantity = action.quantity);
            // remove if quantity is 0
            state.cart.items = state.cart.items.filter(it => it.quantity);

            localStorage.setItem('cart', JSON.stringify(state.cart));
            break;
        case 'remove':
            state.cart.items = state.cart.items.filter(it => it.variant.id != action.id);
            localStorage.setItem('cart', JSON.stringify(state.cart));
            break;
        case 'products':
            state.products = [...action.products];
            //            fuse = new Fuse(state.products, { keys: ['name', 'description'], findAllMatches: true, threshold: 0.4 });
            break;
        case 'filter':
            state.filter = action.filter;
            break;
        case 'filter.in':
            state.filter[action.key] = state.filter[action.key] || [];
            if (action.value) {
                state.filter[action.key].push(action.option);
            } else {
                state.filter[action.key] = state.filter[action.key].filter(it => it !== action.option);
            }
            if (state.filter[action.key].length == 0) {
                delete state.filter[action.key];
            }
            break;
        case 'endanimate':
            state.cartClass = '';
            break;
        default:
            break;
    }
    return { ...state };
}



export function search(state: Model) {
    return (state.search ? fuse.search(state.search).map(it => it.item) : state.products).filter(product => {
        for (const key in state.filter) {
            const value = state.filter[key];
            if (value == undefined) {
                continue;
            } else if (Array.isArray(value)) {
                if (value.includes(product[key])) {
                    continue;
                }
            } else if (value == product[key]) {
                continue;
            }
            return false;
        }
        return true;
    });
}

const hash = (str: any) => {
    if (typeof (str) !== 'string') {
        str = JSON.stringify(str);
    }
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash &= hash; // Convert to 32bit integer
    }
    return new Uint32Array([hash])[0].toString(36);
};





export interface Variant {
    id: any;
    product_id: any;
    title: string;
    price: string;
    sku: string;
    position: number;
    inventory_policy: string;
    compare_at_price?: any;
    fulfillment_service: string;
    inventory_management: string;
    option1: string;
    option2: string;
    option3?: any;
    created_at: Date;
    updated_at: Date;
    taxable: boolean;
    barcode: string;
    grams: number;
    image_id: any;
    weight: number;
    weight_unit: string;
    inventory_item_id: any;
    inventory_quantity: number;
    old_inventory_quantity: number;
    requires_shipping: boolean;
    admin_graphql_api_id: string;
}

export interface Option {
    id: any;
    product_id: any;
    name: string;
    position: number;
    values: string[];
}

export interface Image {
    id: any;
    product_id: any;
    position: number;
    created_at: Date;
    updated_at: Date;
    alt?: any;
    width: number;
    height: number;
    src: string;
    variant_ids: any[];
    admin_graphql_api_id: string;
}

export interface Image2 {
    id: any;
    product_id: any;
    position: number;
    created_at: Date;
    updated_at: Date;
    alt?: any;
    width: number;
    height: number;
    src: string;
    variant_ids: any[];
    admin_graphql_api_id: string;
}

export interface Product {
    id: any;
    title: string;
    body_html: string;
    vendor: string;
    product_type: string;
    created_at: Date;
    handle: string;
    updated_at: Date;
    published_at?: Date;
    template_suffix: string;
    status: string;
    published_scope: string;
    tags: string;
    admin_graphql_api_id: string;
    variants: Variant[];
    options: Option[];
    images: Image[];
    image: Image2;
}

